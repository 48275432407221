import React from 'react';
import { NavLink } from 'react-router-dom';
import GivU_company_logo from "../images/GivU_company_logo.png";
import authrium_logo from "../images/authrium_logo.png"


export default function Footer() {
   
    return (
    <>
        <footer>
            <section className="footer_box">
                <div className="footer_001">
                    <img src={GivU_company_logo} alt="Authrium"/>
                </div>
                <div className="footer_002">
                    <ul className="footer_003">
                        {/* <NavLink className="select" to={'/'}>서비스이용약관</NavLink>
                        <NavLink className="select" to={'/'}>개인정보취급방침</NavLink> */}
                        <li><a href="">서비스이용약관</a></li>
                        <li><a href="">개인정보취급방침</a></li>
                    </ul>
                    <div className="footer_004">
                        <a href="mailto:naegift@authrium.com">naegift@authrium.com</a>
                        <p>Copyright by. ㈜오스리움. All Rights Reserved</p>
                    </div>
                </div>
            </section>
        </footer>
    </>
    );
}