import React, { useState } from 'react';

export default function AlertModal({ header, isConfirm = true, isCancel = true, img, onAction }) {
    const [isOpen, setIsOpen] = useState(false);
    const [modalInfo, setModalInfo] = useState({ mainMsg: '', actionFlag: '' });

    const openModal = (info) => {
        setModalInfo(info);
        setIsOpen(true);
    };

    const closeModal = () => {
        setModalInfo({ mainMsg: '', actionFlag: '' });
        setIsOpen(false);
    };

    const handleAction = () => {
        if (onAction) onAction(modalInfo.actionFlag);
        closeModal();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                {header && <h3 className="modal-header">{header}</h3>}
                <p className="modal-message">{modalInfo.mainMsg}</p>
                {img && <img className="modal-img" src={img} alt="Modal" />}
                <div className="modal-actions">
                    {isCancel && (
                        <button className="modal-button cancel" onClick={closeModal}>
                            취소
                        </button>
                    )}
                    {isConfirm && (
                        <button className="modal-button confirm" onClick={handleAction}>
                            확인
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export function useAlertModal() {
    const [modalProps, setModalProps] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    const openModal = (info) => {
        setModalProps(info);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const Modal = () => (
        <AlertModal
            {...modalProps}
            onAction={(actionFlag) => {
                if (modalProps.onAction) modalProps.onAction(actionFlag);
                closeModal();
            }}
        />
    );

    return { openModal, closeModal, Modal };
}