import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LeftMenu from '../../components/leftMenu';
import AlertModal from '../../components/alertModal';
import SearchConditions from '../../components/SearchConditions';
import GiftListTable from '../../components/GiftListTable';
import GiftListButtons from '../../components/GiftListButtons';
import Pagination from '../../components/pagination';
import { useSelector, useDispatch } from "react-redux";
import { Config } from "../../common/config";
import axios from "axios";
import { userLogin } from "../../reducers/userInfo";
import translations from "../../data/translation";

export default function GiftList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.userInfo);
    const language = useSelector((state) => state.language.language);
    const t = translations[language];

    const [modalOpen, setModalOpen] = useState(false);
    const [modalInfo, setModalInfo] = useState({ isCancel: false, isConfirm: true, isMessage: true, headerMsg: '', mainMsg: '' });
    const [searchCondition, setSearchCondition] = useState({
        flag: false,
        pageSize: Config.PAGE_SIZE_LIST[0],
        sortOrd: '',
        startDttm: '',
        endDttm: '',
        giftIdOrd: '',
        saleOrd: '',
        state: ''
    });
    const [total, setTotal] = useState(0);
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);

    const getData = async () => {
        try {
            let params = `?storeNo=${userInfo.storeNo}&historyYn=Y&pageNo=${currentPage}&pageSize=${searchCondition.pageSize}`;
            if (searchCondition.sortOrd) params += `&sortOrd=${searchCondition.sortOrd}`;
            if (searchCondition.startDttm) params += `&startDttm=${searchCondition.startDttm}`;
            if (searchCondition.endDttm) params += `&endDttm=${searchCondition.endDttm}`;
            if (searchCondition.giftIdOrd) params += `&giftIdOrd=${searchCondition.giftIdOrd}`;
            if (searchCondition.saleOrd) params += `&saleOrd=${searchCondition.saleOrd}`;
            if (searchCondition.state) params += `&state=${searchCondition.state}`;

            const response = await axios.get(`${Config.API_SERVER}gift${params}`, {
                headers: { Authorization: `Bearer ${userInfo.jwtToken}` },
            });

            if (response?.data?.data) {
                const count = response.data.data.total;
                setTotalPage(Math.ceil(count / searchCondition.pageSize));
                setTotal(count);
                setData(response.data.data.list);
            }
            setSearchCondition({ ...searchCondition, flag: false });
        } catch (error) {
            if (error.response?.data?.resultCode === 401) {
                const autologin = localStorage.getItem("autologin");
                if (autologin) {
                    refetchJwt();
                } else {
                    setModalInfo({
                        ...modalInfo,
                        actionFlag: 'login',
                        mainMsg: "유효기간이 만료되었으니 다시 로그인해주세요",
                    });
                    setModalOpen(true);
                }
            } else {
                console.error("Error fetching data:", error);
            }
        }
    };

    const refetchJwt = async () => {
        try {
            const response = await axios.post(`${Config.API_SERVER}auth/getuser`, {
                userId: userInfo.id,
                pwd: userInfo.pwd,
            });

            const jwtToken = response.data.data.accessToken;
            dispatch(userLogin({ ...userInfo, jwtToken }));
            getData(jwtToken);
        } catch (error) {
            console.error("Error refreshing JWT:", error);
        }
    };

    useEffect(() => {
        if (userInfo.id) {
            getData();
        }
    }, [userInfo, currentPage, searchCondition.flag]);

    return (
        <>
            <AlertModal
                open={modalOpen}
                close={() => setModalOpen(false)}
                header={modalInfo.headerMsg}
                isConfirm={modalInfo.isConfirm}
                isMessage={modalInfo.isMessage}
                isCancel={modalInfo.isCancel}
            >
                {modalInfo.mainMsg}
            </AlertModal>

            <section className="location_box">
                <ul className="location_001">
                    <li>{t.home}</li>
                    <li>{t.myPage}</li>
                    <li>{t.giftManagement}</li>
                </ul>
            </section>

            <main>
                <section className="contants_box contants_box_mypage">
                    <LeftMenu pageName="gift" />

                    <section className="rightcontant_box">
                        <h2 className="tit_01">{t.giftManagement}</h2>

                        <SearchConditions
                            t={t}
                            language={language}
                            searchCondition={searchCondition}
                            setSearchCondition={setSearchCondition}
                            getSearch={() => setSearchCondition({ ...searchCondition, flag: true })}
                            getClear={() =>
                                setSearchCondition({
                                    ...searchCondition,
                                    sortOrd: '',
                                    startDttm: '',
                                    endDttm: '',
                                    giftIdOrd: '',
                                    saleOrd: '',
                                    state: '',
                                })
                            }
                        />

                        <GiftListTable
                            t={t}
                            data={data}
                            modGift={(giftNo) => navigate(`/gift-manage/${giftNo}`)}
                            sales={(giftId) => navigate(`/gift-sales/${giftId}`)}
                        />

                        <p className="table_01">
                            {t.totalCount} : {total} {t.count}
                        </p>

                        <ul className="page_box">
                            <Pagination totalPage={totalPage} onPageChange={(page) => setCurrentPage(page)} />
                        </ul>

                        <GiftListButtons
                            t={t}
                            navigate={navigate}
                            sales={(giftId) => navigate(`/gift-sales/${giftId}`)}
                            modGift={(giftNo) => navigate(`/gift-manage/${giftNo}`)}
                        />
                    </section>
                </section>
            </main>
        </>
    );
}